* {
	box-sizing: border-box;
}
html {
	height: 100%;
}
body {
	height: 100%;
	margin: 0;
	padding: 0;
	background: #fff;
	font-family: 'Open Sans', sans-serif;
}
._error {
	margin: 15px;
	outline: 1px solid #d50724;
	background-color: #fce8e8;
	padding: 5px 10px;
}
.dws_form, .dws_tbody {
	position: relative;
}
.loader {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.justify-content-between button[type="button"] {
	margin-right: 10px;
}
.not-found {
	margin-top: 30px;
}
.not-found h2 {
	margin-bottom: 20px;
	color: tomato;
	font-size: 28px;
}
.radial-bg {
	background: #2d77bb;
	background: radial-gradient(circle, #8dd5ff 0%, #268ff8 100%);
}
.flex-center {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
}
.white-plate {
	background-color: #fff;
	border-radius: 16px;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 40px;
	padding-right: 40px;
	padding-bottom: 55px;
	box-shadow: 0px 10px 62px 10px #133b9a;
}
.white-plate.white-plate--payment {
	max-width: 400px;
	padding-bottom: 25px;
	box-shadow: 0px 10px 62px 10px rgba(47, 128, 214, 0.71);
}
.white-plate__header {
	padding-top: 25px;
	padding-bottom: 25px;
}
.white-plate__logo {
	font-weight: 800;
	font-size: 34px;
	color: #2789e6;
	transition: 0.2s ease-in;
	margin: 0;
}
.white-plate__logo span {
	color: #2b2b2b;
}
@media (max-width: 576px) {
	.white-plate__logo {
		display: block;
		text-align: center;
	}
}
.white-plate__line-between {
	background-color: #2d93f0;
	height: 60px;
	margin-left: -55px;
	margin-right: -55px;
	margin-bottom: 40px;
}
.white-plate__line-between--main {
	background-color: #2d93f0;
	height: 45px;
	margin-bottom: 30px;
}
.body--dashboard {
	padding-left: 330px;
}
.left-panel {
	background-color: #094071;
	width: 300px;
	height: 100%;
	left: 0;
	top: 0;
	bottom: 0;
	color: #fff;
	position: fixed;
	z-index: 1;
}
.left-panel__logo {
	padding-top: 41px;
	padding-left: 50px;
	padding-bottom: 40px;
	border-bottom: 1px solid #14497a;
}
.left-panel__logo-title {
	font-family: 'Montserrat', sans-serif;
	color: #fff;
	font-size: 33px;
	font-weight: 700;
}
.left-panel__logo-subtitle {
	color: #8aacce;
	font-size: 14px;
	font-weight: 400;
	text-transform: uppercase;
}
.left-panel__user {
	padding-left: 46px;
	padding-right: 30px;
	padding-top: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #14497a;
}
.left-panel__user-photo {
	width: 56px;
	height: 56px;
	border-radius: 80px;
	float: left;
	overflow: hidden;
}
.left-panel__user-photo img {
	max-width: 100%;
	height: auto;
}
.left-panel__user-name {
	font-size: 18px;
	line-height: 1.3;
	font-weight: 600;
	margin-left: 76px;
}
.left-panel__navigation {
	color: #cbdceb;
	font-size: 16px;
	padding-left: 50px;
	padding-top: 35px;
	padding-bottom: 15px;
	border-bottom: 1px solid #14497a;
}
.left-panel__navigation--no-line {
	border-bottom: none;
}
.left-panel__navigation-title {
	font-size: 18px;
	color: #8eb6da;
	font-weight: 200;
	margin-bottom: 10px;
	text-transform: uppercase;
}
.left-panel__navigation ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
.left-panel__navigation ul li {
	margin: 0;
	padding: 0;
	margin-bottom: 0px;
}
.left-panel__navigation a {
	color: #cbdceb;
	display: block;
	width: 280px;
	position: relative;
	z-index: 2;
	transition: 250ms all ease-in;
	margin-left: -31px;
	padding-left: 31px;
	padding-top: 15px;
	padding-bottom: 18px;
	background-color: #094071;
	border-radius: 80px 0px 0px 80px;
	transition: 100ms all ease-out;
}
.left-panel__navigation a:hover,
.left-panel__navigation a.active {
	text-decoration: none;
	background-color: #2865b1;
	color: #fff;
}
.left-panel__navigation .badge {
	background-color: #2865b1;
	color: #fff;
	border-radius: 32px;
	padding: 6px 10px 5px;
	line-height: 1;
	font-size: 14px;
}
.left-panel__navigation .badge:first-child {
	margin-left: 5px;
}
.main-wrapper {
	margin-left: 0px;
	padding-top: 0px;
	padding-bottom: 20px;
}
.form-wrapper {
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
}
.admin-heading-1 {
	font-size: 34px;
	font-weight: 200;
	margin: 20px 0 10px;
}
.badge {
	padding: 6px 10px 5px;
	font-size: 14px;
}
.mt-0 {
	margin-top: 0;
}
.mt-5 {
	margin-top: 5px;
}
.mt-10 {
	margin-top: 10px;
}
.mt-15 {
	margin-top: 15px;
}
.mt-20 {
	margin-top: 20px;
}
.mt-25 {
	margin-top: 25px;
}
.mt-30 {
	margin-top: 30px !important;
}
.mt-35 {
	margin-top: 35px;
}
.mt-40 {
	margin-top: 40px;
}
.mt-45 {
	margin-top: 45px;
}
.mt-50 {
	margin-top: 50px;
}
.mt-55 {
	margin-top: 55px;
}
.mt-60 {
	margin-top: 60px;
}
.mt-65 {
	margin-top: 65px;
}
.mt-70 {
	margin-top: 70px;
}
.mt-75 {
	margin-top: 75px;
}
.mt-80 {
	margin-top: 80px;
}
.mt-85 {
	margin-top: 85px;
}
.mt-90 {
	margin-top: 90px;
}
.mt-95 {
	margin-top: 95px;
}
.mt-100 {
	margin-top: 100px;
}
.mb-0 {
	margin-bottom: 0;
}
.mb-5 {
	margin-bottom: 5px;
}
.mb-10 {
	margin-bottom: 10px;
}
.mb-15 {
	margin-bottom: 15px !important;
}
.mb-20 {
	margin-bottom: 20px;
}
.mb-25 {
	margin-bottom: 25px;
}
.mb-30 {
	margin-bottom: 30px;
}
.mb-35 {
	margin-bottom: 35px;
}
.mb-40 {
	margin-bottom: 40px;
}
.mb-45 {
	margin-bottom: 45px;
}
.mb-50 {
	margin-bottom: 50px;
}
.mb-55 {
	margin-bottom: 55px;
}
.mb-60 {
	margin-bottom: 60px;
}
.mb-65 {
	margin-bottom: 65px;
}
.mb-70 {
	margin-bottom: 70px;
}
.mb-75 {
	margin-bottom: 75px;
}
.mb-80 {
	margin-bottom: 80px;
}
.mb-85 {
	margin-bottom: 85px;
}
.mb-90 {
	margin-bottom: 90px;
}
.mb-95 {
	margin-bottom: 95px;
}
.mb-100 {
	margin-bottom: 100px;
}
.mb-120 {
	margin-bottom: 120px;
}
.ml-0 {
	margin-left: 0;
}
.ml-5 {
	margin-left: 5px;
}
.ml-10 {
	margin-left: 10px;
}
.ml-15 {
	margin-left: 15px;
}
.ml-20 {
	margin-left: 20px;
}
.ml-25 {
	margin-left: 25px;
}
.ml-30 {
	margin-left: 30px;
}
.ml-35 {
	margin-left: 35px;
}
.ml-40 {
	margin-left: 40px;
}
.ml-45 {
	margin-left: 45px;
}
.ml-50 {
	margin-left: 50px;
}
.ml-55 {
	margin-left: 55px;
}
.ml-60 {
	margin-left: 60px;
}
.ml-65 {
	margin-left: 65px;
}
.ml-70 {
	margin-left: 70px;
}
.ml-75 {
	margin-left: 75px;
}
.ml-80 {
	margin-left: 80px;
}
.ml-85 {
	margin-left: 85px;
}
.ml-90 {
	margin-left: 90px;
}
.ml-95 {
	margin-left: 95px;
}
.ml-100 {
	margin-left: 100px;
}
.mr-0 {
	margin-right: 0;
}
.mr-5 {
	margin-right: 5px;
}
.mr-10 {
	margin-right: 10px;
}
.mr-15 {
	margin-right: 15px;
}
.mr-20 {
	margin-right: 20px;
}
.mr-25 {
	margin-right: 25px;
}
.mr-30 {
	margin-right: 30px;
}
.mr-35 {
	margin-right: 35px;
}
.mr-40 {
	margin-right: 40px;
}
.mr-45 {
	margin-right: 45px;
}
.mr-50 {
	margin-right: 50px;
}
.mr-55 {
	margin-right: 55px;
}
.mr-60 {
	margin-right: 60px;
}
.mr-65 {
	margin-right: 65px;
}
.mr-70 {
	margin-right: 70px;
}
.mr-75 {
	margin-right: 75px;
}
.mr-80 {
	margin-right: 80px;
}
.mr-85 {
	margin-right: 85px;
}
.mr-90 {
	margin-right: 90px;
}
.mr-95 {
	margin-right: 95px;
}
.mr-100 {
	margin-right: 100px;
}
.no-margins {
	margin: 0;
}
.pt-0 {
	padding-top: 0;
}
.pt-5 {
	padding-top: 5px;
}
.pt-10 {
	padding-top: 10px;
}
.pt-15 {
	padding-top: 15px;
}
.pt-20 {
	padding-top: 20px;
}
.pt-25 {
	padding-top: 25px;
}
.pt-30 {
	padding-top: 30px;
}
.pt-35 {
	padding-top: 35px;
}
.pt-40 {
	padding-top: 40px;
}
.pt-45 {
	padding-top: 45px;
}
.pt-50 {
	padding-top: 50px;
}
.pt-55 {
	padding-top: 55px;
}
.pt-60 {
	padding-top: 60px;
}
.pt-65 {
	padding-top: 65px;
}
.pt-70 {
	padding-top: 70px;
}
.pt-75 {
	padding-top: 75px;
}
.pt-80 {
	padding-top: 80px;
}
.pt-85 {
	padding-top: 85px;
}
.pt-90 {
	padding-top: 90px;
}
.pt-95 {
	padding-top: 95px;
}
.pt-100 {
	padding-top: 100px;
}
.pb-0 {
	padding-bottom: 0;
}
.pb-5 {
	padding-bottom: 5px;
}
.pb-10 {
	padding-bottom: 10px;
}
.pb-15 {
	padding-bottom: 15px;
}
.pb-20 {
	padding-bottom: 20px;
}
.pb-25 {
	padding-bottom: 25px;
}
.pb-30 {
	padding-bottom: 30px;
}
.pb-35 {
	padding-bottom: 35px;
}
.pb-40 {
	padding-bottom: 40px;
}
.pb-45 {
	padding-bottom: 45px;
}
.pb-50 {
	padding-bottom: 50px;
}
.pb-55 {
	padding-bottom: 55px;
}
.pb-60 {
	padding-bottom: 60px;
}
.pb-65 {
	padding-bottom: 65px;
}
.pb-70 {
	padding-bottom: 70px;
}
.pb-75 {
	padding-bottom: 75px;
}
.pb-80 {
	padding-bottom: 80px;
}
.pb-85 {
	padding-bottom: 85px;
}
.pb-90 {
	padding-bottom: 90px;
}
.pb-95 {
	padding-bottom: 95px;
}
.pb-100 {
	padding-bottom: 100px;
}
.pb-100 {
	padding-bottom: 100px;
}
.pb-120 {
	padding-bottom: 120px;
}
.pl-0 {
	padding-left: 0;
}
.pl-5 {
	padding-left: 5px;
}
.pl-10 {
	padding-left: 10px;
}
.pl-15 {
	padding-left: 15px;
}
.pl-20 {
	padding-left: 20px;
}
.pl-25 {
	padding-left: 25px;
}
.pl-30 {
	padding-left: 30px;
}
.pl-35 {
	padding-left: 35px;
}
.pl-40 {
	padding-left: 40px;
}
.pl-45 {
	padding-left: 45px;
}
.pl-50 {
	padding-left: 50px;
}
.pl-55 {
	padding-left: 55px;
}
.pl-60 {
	padding-left: 60px;
}
.pl-65 {
	padding-left: 65px;
}
.pl-70 {
	padding-left: 70px;
}
.pl-75 {
	padding-left: 75px;
}
.pl-80 {
	padding-left: 80px;
}
.pl-85 {
	padding-left: 85px;
}
.pl-90 {
	padding-left: 90px;
}
.pl-95 {
	padding-left: 95px;
}
.pl-100 {
	padding-left: 100px;
}
.pr-0 {
	padding-right: 0;
}
.pr-5 {
	padding-right: 5px;
}
.pr-10 {
	padding-right: 10px;
}
.pr-15 {
	padding-right: 15px;
}
.pr-20 {
	padding-right: 20px;
}
.pr-25 {
	padding-right: 25px;
}
.pr-30 {
	padding-right: 30px;
}
.pr-35 {
	padding-right: 35px;
}
.pr-40 {
	padding-right: 40px;
}
.pr-45 {
	padding-right: 45px;
}
.pr-50 {
	padding-right: 50px;
}
.pr-55 {
	padding-right: 55px;
}
.pr-60 {
	padding-right: 60px;
}
.pr-65 {
	padding-right: 65px;
}
.pr-70 {
	padding-right: 70px;
}
.pr-75 {
	padding-right: 75px;
}
.pr-80 {
	padding-right: 80px;
}
.pr-85 {
	padding-right: 85px;
}
.pr-90 {
	padding-right: 90px;
}
.pr-95 {
	padding-right: 95px;
}
.pr-100 {
	padding-right: 100px;
}
.no-paddings {
	padding: 0;
}
/* Floats */
.float-right {
	float: right;
}
.float-left {
	float: left;
}
.clearfix::after,
.clearfix::before {
	content: '';
	display: table;
	clear: both;
}
/* Text align */
.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}
.text-justify {
	text-align: justify;
}
/* Font weight */
.light {
	font-weight: 300;
}
.normal {
	font-weight: 400;
}
.strong {
	font-weight: 700;
}

.project-nav {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 60px;
	z-index: 999;

	background-color: #e3f7ff;
	color: #fff;
}

.project-nav__links-wrapper {
	display: flex;
	justify-content: center;
	column-gap: 30px;
	align-items: center;
	height: 100%;
}

.project-nav a {
	color: rgb(86, 127, 192);
}

body.with-nav {
	padding-top: 60px;
}

body.with-nav .left-panel {
	top: 60px;
	bottom: 0px;
	height: auto;
	overflow-x: hidden;
	overflow-y: auto;
}
